<script type="text/javascript">
    import FormGroup from '@/components/Form/Group.vue';
    import FormLabel from '@/components/Form/Label.vue';
    import FormInput from '@/components/Form/Input.vue';
    import FormText from '@/components/Form/Text.vue';
    import FormNumber from '@/components/Form/Number.vue';
    import FormDate from '@/components/Form/Date.vue';
    import FormDateTime from '@/components/Form/DateTime.vue';
    import FormFloatingLabel from '@/components/Form/FloatingLabel.vue';
    import InputGroup from '@/components/Form/InputGroup.vue';
    import InputGroupText from '@/components/Form/InputGroupText.vue';
    import FormCheckbox from '@/components/Form/Checkbox.vue';
    import FormRange from '@/components/Form/Range.vue';
    import FormDuelRange from '@/components/Form/DuelRange.vue';
    import FormDob from '@/components/Form/DOB.vue';
    import FormAddress from '@/components/Form/Address.vue';
    import CalendarFormComponents from '@/components/Form/Calendar/index.vue';
    import SelectComponents from '@/components/Form/Select/index.vue';
    import FormFaux from '@/components/Form/Faux.vue';
    import MultiFileInputComponents from '@/components/Form/MultiFileInput/index.vue';
    import FormStarsRating from '@/components/Form/StarsRating.vue';
    import FormTags from '@/components/Form/Tags.vue';
    import FormColor from '@/components/Form/Color.vue';
    import FormToggle from '@/components/Form/Toggle.vue';
    
    export default {
        FormGroup,
        FormLabel,
        FormInput,
        FormText,
        FormNumber,
        FormDate,
        FormDateTime,
        FormFloatingLabel,
        InputGroup,
        InputGroupText,
        FormCheckbox,
        FormRange,
        FormDuelRange,
        FormDob,
        FormAddress,
        FormFaux,
        FormStarsRating,
        FormTags,
        FormColor,
        FormToggle,
        ...MultiFileInputComponents,
        ...CalendarFormComponents,
        ...SelectComponents,
    }
</script>