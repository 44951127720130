<template>
    <popover-peek :title="title || label" :popoverPlacement="placement" :forcePopoverPlacement="forcePlacement" @show="localOpen=true; $emit('show')" @hide="localOpen=false; $emit('hide')" @preShow="$emit('preShow')" @preHide="$emit('preHide')" :open="localOpen" :peekSize="peekSize ? peekSize : (multiLines ? 5 : 4)" :peekAutoSize="peekAutoSize" :addCloseButton="!showButtons" @close="$emit('cancel')"  :noPadding="noPadding">
        <slot/>
        <template #content>
            <div ref="inputContainer">
                <input-group v-if="!multiLines && !uiStore.isMobile">
                    <slot name="field"/>
                    <ui-button v-if="showButtons" @click="localOpen=false; $emit('save')">
                        <i class="fas fa-save"></i>
                    </ui-button>
                    <ui-button v-if="showButtons" @click="localOpen=false; $emit('cancel')" color="secondary">
                        <i class="fas fa-ban"></i>
                    </ui-button>
                </input-group>
                <div v-else>
                    <div style="min-width:320px;">
                        <slot name="field"/>
                    </div>
                    <div class="mt-2 text-center" v-if="showButtons">
                        <button-group>
                            <ui-button @click="localOpen=false; $emit('save')">
                                {{ label }}
                            </ui-button>
                            <ui-button @click="localOpen=false; $emit('cancel')" color="secondary">
                                Cancel
                            </ui-button>
                        </button-group>
                    </div>
                </div>
            </div>

            <slot name="hint"/>
        </template>
    </popover-peek>
</template>

<script type="text/javascript">
    import { UseUIStore } from '@/store/UI.js';

    import ButtonComponents from '@/components/UI/Button/index.vue';
    import PopoverPeek from '@/components/Action/PopoverPeek.vue';
    import InputGroup from '@/components/Form/InputGroup.vue';

    export default {

        components: {
            PopoverPeek,
            InputGroup,
            ...ButtonComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localOpen: false,
            };
        },

        emits: ['save', 'cancel', 'show', 'hide', 'preShow', 'preHide'],

        props : {
            label: {
                type: String,
                default: 'Save',
            },

            title: {
                type: String,
            },

            multiLines: {
                type: Boolean,
                default: false,
            },

            open: {
                type: Boolean,
                default: null,
            },

            showButtons: {
                type: Boolean,
                default: true,
            },

            placement: {
                type: String,
                default: 'auto',
            },

            forcePlacement: {
                type: Boolean,
                default: false,
            },

            peekSize: {
                type: [Number, Object]
            },

            peekAutoSize: {
                type: Boolean,
                default: false,
            },

            noPadding: {
                type: Boolean,
                default: false,
            },
        },

        created() {
            this.localOpen = this.open;
        },

        watch: {
            open(newVal) {
                this.localOpen = newVal;
            }
        },

        methods: {
            openAction() {
                this.$emit('show');
                let input = this.$refs.inputContainer.querySelector('input, select, textarea');
                if (input) input.focus();
            }
        },
    }
</script>