import FormOption from '@/classes/FormOption.js';
import FormOptionsGroup from '@/classes/FormOptionsGroup.js';
import Media from '@/classes/Media.js';
import Utilities from '@/classes/Utilities.js';
import {shallowRef} from 'vue';

/**
 * A class for UI utility functions
 * e.g. validating sizes
 */
export default class UI {
    /**
     * Count the number of forward back buttons
     * so we can display a "back" button
     * when te user navigate from main dashboards
     * @var {Number} backButtonCount
     */
    static backButtonCount = shallowRef(0);

    /**
     * The current page theme
     * @var {String} theme
     */
    static theme = shallowRef('');
    static assignedTheme = 'staymarquis';
    static siteName = shallowRef('staymarquis');

    /**
     * If the main menu is floating
     * @var {Boolean} floatingMenu
     */
    static floatingMenu = shallowRef(false);

    /**
     * Flag if we are in the check-out flow
     * @var {Boolean} checkOutFlow
     */
    static checkOutFlow = shallowRef(false);
    static isWidget = shallowRef(false);

    /**
     * ref mobile and tablet sizes
     * @var {Boolean} mobileScreen
     * @var {Boolean} tabletScreen
     */
    static mobileScreen = shallowRef(false);
    static tabletScreen = shallowRef(false);

    /**
     * Footer CTA details
     * @var {String} footerCtaType
     * @var {String} footerCtaLabel
     */
    static footerCtaType = shallowRef('guests');
    static footerCtaLabel = shallowRef('We are here to answer your questions');

    /**
     * The valid page themes
     * @var {String[]} validThemes
     */
    static validThemes = [
        'staymarquis',
        'casamarquis',
        'light',
    ];

    /**
     * The valid site names in lower case
     * @var {String[]} validSiteNames
     */
    static validSiteNames = [
        'staymarquis',
        'casamarquis',
        'bungalows',
    ];

    /**
     * The app portal for main app layout
     * @var {String} appPortal
     */
    static appPortal = shallowRef('frontEnd');

    /**
     * The valid app portal names
     * @var {String[]} validPortals
     */
    static validPortals = [
        'frontEnd',
        'owners',
        'partners',
        'travelers',
        'account',
        'quiz',
    ];

    /**
     * Other pages that will switch
     * to a different portal when
     * setting the portal
     * @var {Object<String, String>} portalSwithToPortal
     */
    static portalSwithToPortal = {
        'subscribe-200': 'quiz',
        'subscribe-ta': 'quiz',
    };

    /**
     * Override specific portal themes
     * by page
     * @var {Object} keyed by portal name, each item has a usePortal and list of pages that should apply
     */
    static overridePortalTheme = {
        travelers: {
            usePortal: 'staymarquis',
            pages: ['confirm', 'concierge'],
        },
    };


    static adjustBackButtonCount(num) {
        UI.backButtonCount.value = UI.backButtonCount.value + num;
        if (UI.backButtonCount.value < 0) UI.backButtonCount.value = 0;
    }

    static resetBackButtonCount() {
        UI.backButtonCount.value = 0;
    }

    /**
     * Validate the size is one of the accepted values
     * that can be used in different layouts
     * Used in prop validation
     * @param {string} size - the size that was passed to the prop
     * @return {boolean}
     */
    static validateSizes(size) {
        if (size == null) return true;
        return (['sm', 'md', 'lg', 'xl', 'xxl'].indexOf(size) != -1);
    }

    /**
     * Validate the color is one of the accepted values
     * that can be used in different widgets
     * Used in prop validation
     * @param {string} color - the color that was passed to the prop
     * @return {boolean}
     */
     static validateColors(color) {
         if (color == null) return true;
        return (['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'white', 'darker-light'].indexOf(color) != -1);
    }

    /**
     * Validate all form options are using the correct
     * type {FormOption}
     * @param {array} options the list of options
     * @return {boolean}
     */
    static validateFormOptions(options) {
        if (options == null) return true;
        for (let i=0; i<options.length; i++) {
            if (
                (!(options[i] instanceof FormOption)) &&
                (!(options[i] instanceof FormOptionsGroup))
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Validate all form options are using the correct
     * type {FormOption}
     * @param {array} options the list of options
     * @return {boolean}
     */
    static validateFormOptionsExcludeGroup(options) {
        if (options == null) return true;
        for (let i=0; i<options.length; i++) {
            if (
                (!(options[i] instanceof FormOption))
            ) {
                return false;
            }
        }
        return true;
    }

    /**
     * Validate all form media assets are using the correct
     * type {Media}
     * @param {array} media the list of options
     * @return {boolean}
     */
     static validateMediaType(options) {
        if (options == null) return true;
        for (let i=0; i<options.length; i++) {
            if (!(options[i] instanceof Media)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets an object of the break points for the grid layout
     * @return {object} keyd by break points
     */
    static breakPoints() {
        return {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1900,
        };
    }

    /**
     * Set the proxy ref screen sizes
     * @void
     */
    static setScreenRefs() {
        UI.mobileScreen.value = UI.isMobile();
        UI.tabletScreen.value = UI.isTablet();
    }

    /**
     * Check if the device or screen size is a mobile device
     * smaller or equal to "sm"
     * @return {boolean}
     */
    static isMobile() {
        let sizes = UI.breakPoints();
        return (window.innerWidth <= sizes.sm);
    }

    /**
     * Check if the device or screen size is a tablet device
     * smaller or equal to "md"
     * @return {boolean}
     */
    static isTablet() {
        let sizes = UI.breakPoints();
        return ((!this.isMobile()) && (window.innerWidth <= sizes.lg));
    }
    
    /**
     * Get the text color for a pre-defined
     * background color
     * @param {String} bg the background color (e.g. primary)
     * @returns {String} "white" or "black"
     */
    static textColor(bg) {
        let darkBgs = ['primary', 'success', 'secondary', 'dark', 'danger', 'info'];
        if (UI.isDarkTheme()) {
            darkBgs = ['light', 'secondary', 'danger', 'info'];
        }
        if (darkBgs.indexOf(bg) != -1) return 'white';
        return 'black';
    }

    /**
     * Get a list of colors for the calendar wedges
     * @param {String} type the color type, if null, returns an object with all option
     *          @see object colors for possible values
     * @return {String|Object} the color based on the type or the entire list
     */
    static calendarColors(type) {
        let colors = {
            booked: '#26be71',
            requested: '#5ccdb7',
            opened: '#bae9ee',
            blocked: '#ffd1d1',
            turnover: '#f8f1da',
        };

        if (type) {
            if (colors[type]) return colors[type];
            return '#000000';   // fail save to bring attention to the missing type
        }

        return colors;
    }

    /**
     * Get the range color hover color
     * @param {Boolean} circleStyle if we are using circle style wedges
     * @return {String} the css color
     */
    static rangeCalendarHoverColor(circleStyle) {
        if (UI.isDarkTheme()) {

            if (circleStyle) {
                return 'info';
            }
            else {
                return 'rgba(255, 255, 255, 0.1)';
            }
        }
        else {
            return 'rgba(0, 0, 0, 0.1)';
        }
    }

    /**
     * Get the range color selected range color
     * @return {String} the css color
     */
    static rangeCalendarSelectedColor(circleStyle) {
        if (UI.isDarkTheme()) {
            if (circleStyle) {
                return 'primary';
            }
            return '#67cfd9';
        }
        else {
            return '#bae9ee';
        }
    }

    /**
     * Scrolls a container to the first
     * element that matches the selector
     * @param {HTMLElement} parent the overflow container
     * @param {String|HTMLElement} selector the element we need to scroll to
     * @param {Boolean} immediate if we need to slide immediately (not animated)
     * @param {String} position where to scroll to in the element, "start", "center", "end"
     * @param {Boolean} stopIfVisible if we need to skip scroll if the item is visible
     * @void
     */
    static scrollToChild(parent, selector, immediate, position, stopIfVisible) {
        let child = selector;
        if (typeof selector == 'string') child = parent.querySelector(selector);
        if (!child) return;

        // if the element has a d-contents class
        // it won't have any size, so we'll pick the
        // first element
        while (child.classList.contains('d-contents')) {
            if (child.firstElementChild) {
                child = child.firstElementChild;
            }
            else {
                break;
            }
        }
        
        let top = child.offsetTop - parent.offsetTop;
        let childHeight = child.offsetHeight;
        
        if (position == 'center') {
            let parentHeight = parent.offsetHeight;

            if (childHeight > parentHeight) {
                top += (childHeight - parentHeight) / 2;
            }
            else {
                top -= (parentHeight - childHeight) / 2;
            }
        }
        else if (position == 'end') {
            top += childHeight;
        }

        if (stopIfVisible) {
            if ((child.offsetTop >= parent.scrollTop) && ((child.offsetTop - parent.scrollTop) <= parent.offsetWidth)) {
                return;
            }
        }

        let behavior = immediate ? 'auto' : 'smooth';
        parent.scrollTo({
            top: Math.ceil(top),
            behavior: behavior,
        });
    }

    /**
     * Scrolls a container horizontally to the first
     * element that matches the selector
     * 
     * THIS DOES NOT OFFSET THE PARENT SINCE
     * MOST CONTENT DO NOT HAVE BODY SCROLL
     * 
     * MAKE SURE THE SCROLL CONTAINER IS NOT STATIC POSITIONED
     * OR THE OFFSET PARENT DOENS'T HAVE ANY PADDING
     * (relative will work)
     * 
     * @param {HTMLElement} parent the overflow container
     * @param {String|HTMLElement} selector the element we need to scroll to
     * @param {Boolean} immediate if we need to slide immediately (not animated)
     * @param {String} position where to scroll to in the element, "start", "center", "end"
     * @param {Boolean} stopIfVisible if we need to skip scroll if the item is visible
     * @void
     */
     static hScrollToChild(parent, selector, immediate, position, stopIfVisible) {
        let child = selector;
        if (typeof selector == 'string') child = parent.querySelector(selector);
        if (!child) return;

        // if the element has a d-contents class
        // it won't have any size, so we'll pick the
        // first element
        while (child.classList.contains('d-contents')) {
            if (child.firstElementChild) {
                child = child.firstElementChild;
            }
            else {
                break;
            }
        }

        let left = child.offsetLeft - parent.offsetLeft;
        let childWidth = child.offsetWidth;
        
        
        if (position == 'center') {
            let parentWidth = parent.offsetWidth;

            if (childWidth > parentWidth) {
                left += (childWidth - parentWidth) / 2;
            }
            else {
                left -= (parentWidth - childWidth) / 2;
            }
        }
        else if (position == 'end') {
            left -= childWidth;
        }
        
        if (stopIfVisible) {
            if ((child.offsetLeft >= parent.scrollLeft) && ((child.offsetLeft - parent.scrollLeft) <= parent.offsetWidth)) {
                return;
            }
        }

        let behavior = immediate ? 'auto' : 'smooth';

        parent.scrollTo({
            left: left,
            behavior: behavior,
        });
    }

    /**
     * Changing the app theme
     * @param {String} theme the app portal name
     * @param {String} siteName the site name (e.g. StayMarquis or CasaMarquis)
     * @void
     */
    static updateTheme(theme, siteName) {
        if ((theme) && (UI.validThemes.indexOf(theme) != -1)) {
            UI.assignedTheme = theme;
        }
        else {
            UI.assignedTheme = 'staymarquis';
        }

        // data correction
        siteName = siteName.toLowerCase();
        if (siteName == 'beach & bay bungalows') {
            siteName = 'bungalows';
        }

        if (UI.validSiteNames.indexOf(siteName) == -1) {
            siteName = 'staymarquis';
        }
        if (siteName != UI.siteName.value) {
            UI.siteName.value = siteName;
        }

        UI._applyThemeToPortal();
    }

    /**
     * Changing the app portal
     * @param {String} portal the app portal name
     * @param {String} page the page that we are in, used for confirm and concierge pages
     * @void
     */
    static updateAppPortal(portal, page) {

        // check if we need to switch the portal from page
        if (UI.portalSwithToPortal[portal]) {
            portal = UI.portalSwithToPortal[portal];
        }

        // checking if the we need to override
        // the portal data by page type
        if ((UI.overridePortalTheme) && (UI.overridePortalTheme[portal])) {
            if (UI.overridePortalTheme[portal].pages.indexOf(page) != -1) {
                portal = UI.overridePortalTheme[portal].usePortal;
            }
        }

        let setPortal;
        if ((portal) && (UI.validPortals.indexOf(portal) != -1)) {
            setPortal = portal;
        }
        else {
            setPortal = 'frontEnd';
        }

        if (setPortal != UI.appPortal.value) {
            UI.appPortal.value = setPortal;
            document.documentElement.setAttribute('data-mr-portal', setPortal);
        }

        UI._applyThemeToPortal();
    }

    /**
     * Apply the theme to the page
     * @void
     */
    static _applyThemeToPortal() {
        let useTheme = 'light';
        if ((UI.appPortal.value == 'frontEnd') && (UI.assignedTheme != 'light')) {
            useTheme = UI.assignedTheme;
        }
        if (UI.theme.value != useTheme) {
            UI.theme.value = useTheme;
            document.documentElement.setAttribute('data-bs-theme', useTheme);
        }
    }

    /**
     * Check if the theme of the page is dark
     * @return {Boolean}
     */
    static isDarkTheme() {
        if (
            (UI.theme.value) &&
            (UI.theme.value != 'light') 
        ) {
            return true;
        }
        return false;
    }

    /**
     * Set floating menu
     * @param {Boolean} isFloating
     * @void
     */
    static setFloatingMenu(isFloating) {
        if (!isFloating) isFloating = false;

        if (UI.floatingMenu.value != isFloating) {
            UI.floatingMenu.value = isFloating;
        }
    }

    /**
     * Set if we are in the checkout flow or not
     * @param {Boolean} isCheckOutFlow
     * @void
     */
    static setCheckOutFlow(isCheckOutFlow) {
        if (!isCheckOutFlow) isCheckOutFlow = false;

        if (UI.checkOutFlow.value != isCheckOutFlow) {
            UI.checkOutFlow.value = isCheckOutFlow;
        }
    }

    /**
     * Set if we are in a widget
     * @param {Boolean} isWidget
     * @void
     */
    static setIsWidget(isWidget) {
        if (!isWidget) isWidget = false;
        if (UI.isWidget.value != isWidget) {
            UI.isWidget.value = isWidget;
        }
    }

    /**
     * Check if the side menu should be injected
     * into the main menu on mobile
     * @return {Boolean}
     */
    static injectSideMenuInMobile() {
        if (UI.appPortal.value == 'frontEnd') {
            return false;
        }
        return true;
    }

    /**
     * The default font name (used for charts)
     * @return {String} the font name in CSS format 
     */
    static fontName() {
        return 'unita, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
    }

    /**
     * Same as the above, but for monospace fonts
     * @return {String}
     */
    static monospaceFontName() {
        return 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace';
    }

    /**
     * Get a random pattern image source
     * @return {String}
     */
    static patternImage() {
        return '/img/patterns/0'+Utilities.random(1, 4)+'.png';
    }

    /**
     * Get a list of random exterior images
     * @param {Number} count the number of images needed
     * @return {Array|String} if the number of images is more than one, array, otherwise, string
     */
    static randomExteriorImages(count) {
        if (!count) count = 1;

        // we have 29 images
        let maxImages = 29;

        // create an array of the images
        let arr = [];
        for (let i=0; i<maxImages; i++) {
            arr.push(i+1);
        }
        arr.sort(function() {
            let random = Utilities.random(0, 100);
            if (random > 50) return 1;
            return -1;
        });
        
        let re = [];
        let index = 0;
        for (let i=0; i<count; i++) {
            let imgNum = arr[i];
            if (imgNum < 10) imgNum = '0'+imgNum;
            re.push('/img/headers/exterior-'+imgNum+'.jpg');
            index++;
            if (index >= arr.length) index = 0;
        }
        if (count == 1) return re[0];
        return re;
    }

    /**
     * Get a list of random interior images
     * @param {Number} count the number of images needed
     * @return {Array|String} if the number of images is more than one, array, otherwise, string
     */
    static randomInteriorImages(count) {
        if (!count) count = 1;

        // we have 29 images
        let maxImages = 13;

        // create an array of the images
        let arr = [];
        for (let i=0; i<maxImages; i++) {
            arr.push(i+1);
        }
        arr.sort(function() {
            let random = Utilities.random(0, 100);
            if (random > 50) return 1;
            return -1;
        });
        
        let re = [];
        let index = 0;
        for (let i=0; i<count; i++) {
            let imgNum = arr[i];
            if (imgNum < 10) imgNum = '0'+imgNum;
            re.push('/img/headers/interior-'+imgNum+'.jpg');
            index++;
            if (index >= arr.length) index = 0;
        }
        if (count == 1) return re[0];
        return re;
    }

    /**
     * Set the footer CTA type and label
     * @param {String} type the cta type
     * @param {String} label the cta label
     */
    static setFooterCta(type, label) {
        UI.footerCtaType.value = type || 'guests';
        UI.footerCtaLabel.value = label || 'We are here to answer your questions'
    }
}

UI.setScreenRefs();
window.addEventListener('resize', UI.setScreenRefs, false);
window.addEventListener('load', UI.setScreenRefs, false);