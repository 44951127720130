export default [
    
    {
        path: '/offline/:propertyId(\\d+)/:hash/:addresHash?',
        components: {
            default: () => import(/* webpackChunkName: "public.external.properties" */ '@/views/Public/External/Properties/View.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/offline',
            multiRoute: 'public.external.properties',
        }
    },
    
    
  ];