<template>
    <div class="text-center">
        <div class="d-inline-block rounded-pill position-relative text-nowrap" :class="cssClass">

            <ui-button v-if="hoverButtonLeft != null" :style="`left:${hoverButtonLeft}px; --bs-btn-bg:var(--bs-btn-hover-bg);`" :color="offColor" class="position-absolute px-md-5 border-0">
                <span class="opacity-0 pe-none">{{ spacerLabel }}</span>
            </ui-button>

            <ui-button v-if="bgButtonLeft != null" :style="`transition: left 0.25s; left:${bgButtonLeft}px;`" :color="onColor" class="position-absolute px-md-5 border-0">
                <span class="opacity-0 pe-none">{{ spacerLabel }}</span>
            </ui-button>

            <div v-for="(option, index) in options" :key="`form-toggle-option-${option.value}-${index}`" ref="toggleButtons" class="position-relative d-inline-block" :style="btnStyle(index)">
                <!-- we need to remove the mouse events from mobile -->
                <ui-button v-if="uiStore.isMobile || uiStore.isTablet" :color="(selectedButtonIndex == index) ? onColor : offColor" @click="() => setValue(option, index)" class="px-md-5 border-0" style="background:none !important;">
                    <span class="opacity-0 pe-none">{{ spacerLabel }}</span>
                    <div class="position-absolute top-0 bottom-0 end-0 start-0 d-flex align-items-center justify-content-center">
                        {{ option.label }}
                    </div>
                </ui-button>
                <!-- desktop button -->
                <ui-button v-else :color="(selectedButtonIndex == index) ? onColor : offColor" @click="() => setValue(option, index)" class="px-md-5 border-0" style="background:none !important;" @mouseenter="hoverIndex=index" @mouseleave="hoverIndex=null">
                    <span class="opacity-0 pe-none">{{ spacerLabel }}</span>
                    <div class="position-absolute top-0 bottom-0 end-0 start-0 d-flex align-items-center justify-content-center">
                        {{ option.label }}
                    </div>
                </ui-button>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import UiButton from '@/components/UI/Button/Button.vue';
    
    import { UseUIStore } from '@/store/UI.js';

    export default {
        components: {
            UiButton,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localValue: null,
                hoverIndex: null,
                hoverButtonLeft: null,
                selectedButtonIndex: null,
                bgButtonLeft: null,
                mounted: false,
            };
        },

        emits: ['update:modelValue', 'input', 'change'],

        props: {
            value: {
                default: null,
            },
            modelValue: {
                default: null,
            },

            options: {
                type: Array,
                required: true,
                validator: function(options) {
                    if ((options == null) || (options.length == 0)) return false;
                    return UI.validateFormOptionsExcludeGroup(options);
                },
            },

            size: {
                type: String,
            },

            valid: {
                type: Boolean,
                default: null,
            },

            onColor: {
                type: String,
                default: 'primary',
            },

            offColor: {
                type: String,
                default: 'info',
            },

            invalidColor: {
                type: String,
                default: 'danger',
            },
        },

        watch: {
            modelValue(newVal) {
                this.setInitialValue(newVal);
            },

            value(newVal) {
                this.setInitialValue(newVal);
            },

            hoverIndex() {
                this.setHoverButtonPosition();
            },
        },

        created() {
            this.setInitialValue(this.modelValue || this.value);
        },

        mounted() {
            requestAnimationFrame(function() {
                this.mounted = true;
            }.bind(this));
        },

        computed: {
            spacerLabel() {
                let lbl = '';
                if ((this.options) && (this.options.length)) {
                    for (let i=0; i<this.options.length; i++) {
                        if (this.options[i].label.length > lbl.length) {
                            lbl = this.options[i].label;
                        }
                    }
                }

                return lbl;
            },

            cssClass() {
                if (this.valid === false) {
                    return 'bg-'+(this.invalidColor || 'danger');
                }
                return 'bg-'+(this.offColor || 'info');
            },
        },

        methods: {
            btnStyle(index) {
                if (index > 0) {
                    if (this.uiStore.isMobile) {
                        return 'margin-left:-10px;'; 
                    }
                    return 'margin-left:-30px;';
                }
                return null;
            },

            emitEvent() {
                this.$emit('update:modelValue', this.localValue);
                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
            },

            setInitialValue(val) {
                let valueSet = false;
                if (
                    (this.options) &&
                    (this.options.length)
                ) {
                    for (let i=0; i<this.options.length; i++) {
                        if (this.options[i].value == val) {
                            this.setValue(this.options[i], i, true);
                            valueSet = true;
                            break;
                        }
                    }
                }

                if (!valueSet) {
                    this.setValue(null, null, true);
                }
            },

            setValue(option, index, skipEmit) {
                if (option) {
                    this.localValue = option.value;
                    this.selectedButtonIndex = index;
                }
                else {
                    this.localValue = null;
                    this.selectedButtonIndex = null;
                }
                if (!skipEmit) {
                    this.emitEvent();
                }
                this.setBgButtonPosition();
            },

            setBgButtonPosition() {
                if (!this.mounted) {
                    setTimeout(this.setBgButtonPosition, 60);
                    return;
                }

                if (this.selectedButtonIndex != null) {
                    if (
                        (this.$refs.toggleButtons) &&
                        (this.$refs.toggleButtons.length) &&
                        (this.$refs.toggleButtons.length > this.selectedButtonIndex)
                    ) {
                        this.bgButtonLeft = this.$refs.toggleButtons[this.selectedButtonIndex].offsetLeft;
                    }
                    else {
                        this.bgButtonLeft = null;
                    }
                }
                else {
                    this.bgButtonLeft = null;
                }
            },

            setHoverButtonPosition() {
                if (!this.mounted) {
                    setTimeout(this.setHoverButtonPosition, 60);
                    return;
                }

                if ((this.hoverIndex != null) && (this.hoverIndex != this.selectedButtonIndex)) {
                    if (
                        (this.$refs.toggleButtons) &&
                        (this.$refs.toggleButtons.length) &&
                        (this.$refs.toggleButtons.length > this.hoverIndex)
                    ) {
                        this.hoverButtonLeft = this.$refs.toggleButtons[this.hoverIndex].offsetLeft;
                    }
                    else {
                        this.hoverButtonLeft = null;
                    }
                }
                else {
                    this.hoverButtonLeft = null;
                }
            },
            
        }
    }
</script>