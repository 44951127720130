<template>
    <nav v-if="computedNumberPages > 1" class="d-flex justify-content-center">
        <div class="mx-auto d-inline-flex align-items-baseline" :class="cssClass">
            <a v-for="(button, i) in listButtons" :key="`pagination-button-${i}`" class="box-link d-flex align-items-center justify-content-center aspect-ratio-1x1" :class="buttonCssClass(button)" @click.prevent="clickAction(button)" :href="pageLink(button)">
                <left-arrow-icon v-if="button.label == 'Previous'" :size="14"/>
                <right-arrow-icon v-else-if="button.label == 'Next'" :size="14"/>
                <div v-else-if="button.label == '...'">&mldr;</div>
                <div v-else class="p-1">{{ button.label }}</div>
            </a>
        </div>
    </nav>
</template>

<script type="text/javascript">
    import UI from '@/classes/UI.js';
    import LeftArrowIcon from '@/assets/icons/left-arrow.svg.vue';
    import RightArrowIcon from '@/assets/icons/right-arrow.svg.vue';
    
    import Collection from '@/classes/Collection.js';

    export default {
        components: {
            LeftArrowIcon,
            RightArrowIcon,
        },

        data() {
            return {
                selectedValue: 1,
            }
        },

        props : {
            numberPages: {
                type: Number,
                default: 1,
            },

            modelValue: {
                type: Number,
                default: 1
            },

            currentPage: {
                type: Number,
                default: 1,
            },

            size: {
                type: String,
            },

            align: {
                type: String,
            },

            collection: {
                type: Collection,
            },
        },

        emits: ['update:modelValue', 'input', 'change'],

        created() {
            if (this.collection) {
                this.selectedValue = this.collection.currentPage;
            }
            else if (this.modelValue != null) {
                this.selectedValue = this.modelValue;
            }
            else {
                this.selectedValue = this.currentPage;
            }
        },

        watch: {
            modelValue() {
                this.selectedValue = this.modelValue;
            },

            collection() {
                this.selectedValue = this.collection.currentPage;
            },
        },

        computed: {
            computedNumberPages() {
                if (this.collection) {
                    return this.collection.numberPages;
                }
                else {
                    return this.numberPages;
                }
            },

            listButtons() {
                // get the start from
                let numberPages = this.computedNumberPages;
                let currentPage = this.selectedValue;

                let buttons = [];
                buttons.push({
                        label: 'Previous',
                        active: false,
                        disabled: (currentPage == 1),
                        page: currentPage - 1,
                    }
                );

                let rangeStart = currentPage - 2;
                let rangeEnd = currentPage + 2;
                if (UI.isMobile()) {
                    rangeStart = currentPage - 1;
                    rangeEnd = currentPage + 1;
                }

                if (rangeStart < 1) rangeStart = 1;
                if (rangeEnd > numberPages) rangeEnd = numberPages;

                let loopEnd = 2;
                if (UI.isMobile()) {
                    loopEnd = 1;
                }

                for (let i=1; i<=loopEnd; i++) {
                    if (i < rangeStart) {
                        buttons.push(
                            {
                                label: i,
                                active: (i == currentPage),
                                disabled: false,
                                page: i,
                            }
                        );
                    }
                }

                if (rangeStart > (loopEnd + 1)) {
                    if (rangeStart == (loopEnd + 2)) {
                        rangeStart--;
                    }
                    else {
                        buttons.push(
                            {
                                label: '...',
                                active: false,
                                disabled: true,
                                page: currentPage,
                            }
                        );
                    }
                }

                for (let i=rangeStart; i<=rangeEnd; i++) {
                    buttons.push(
                        {
                            label: i,
                            active: (i == currentPage),
                            disabled: false,
                            page: i,
                        }
                    );
                }

                let loopStart = numberPages - 1;
                if (UI.isMobile) {
                    loopStart = numberPages;
                }

                if (rangeEnd < loopStart) {
                    if (rangeEnd + 1 == loopStart) {
                        loopStart--;
                    }
                    else if (rangeEnd + 2 == loopStart) {
                        loopStart -= 2;
                    }
                    else {
                        buttons.push(
                            {
                                label: '...',
                                active: false,
                                disabled: true,
                                page: currentPage,
                            }
                        );
                    }
                }

                for (let i=loopStart; i<=numberPages; i++) {
                    if (i > rangeEnd) {
                        buttons.push(
                            {
                                label: i,
                                active: (i == currentPage),
                                disabled: false,
                                page: i,
                            }
                        );
                    }
                }

                buttons.push(
                    {
                        label: 'Next',
                        active: false,
                        disabled: (currentPage == numberPages),
                        page: currentPage + 1,
                    }
                );

                return buttons;
            },

            listOptions() {
                let list = this.listButtons.filter(button => !button.disabled);
                return list;
            },

            cssClass() {
                let cls = [];
                if (this.size) {
                    cls.push('pagination-'+this.size);
                }
                if (this.align) {
                    cls.push('justify-content-'+this.align);
                }
                else {
                    cls.push('justify-content-center');
                }
                return cls;
            },
        },

        methods: {
            buttonCssClass(button) {
                let cls = [];
                if (button.disabled) {
                    cls.push('opacity-50');
                    cls.push('text-muted');
                }
                else {
                    cls.push('clickable');
                }
                
                if (button.active) {
                    cls.push('border');
                    cls.push('rounded-circle');
                }

                if (button.label == 'Next') {
                    cls.push('px-2');
                    cls.push('ms-1');
                }
                else if (button.label == 'Previous') {
                    cls.push('px-2');
                    cls.push('me-1');
                }
                else if (button.label != '...') {
                    cls.push('px-2');
                    cls.push('mx-1');
                }
                else {
                    cls.push('px-2');
                }
                return cls;
            },

            pageLink(button) {
                if (
                    (button.disabled) ||
                    (button.label == '...')
                ) {
                    return '#';
                }

                let url = location.href;
                url = url.replace(/page=\d+&?/, '');
                // if the last character in the url is ?, remove it
                if (url.charAt(url.length - 1) == '?') url = url.substring(0, url.length - 1);

                if (url.indexOf('?') == -1) url += '?';
                else url += '&';

                let lbl = button.label;
                if (lbl == 'Next') lbl = this.selectedValue + 1;
                else if (lbl == 'Previous') lbl = this.selectedValue - 1;
                url += 'page='+lbl;

                return url;
            },

            clickAction(button) {
                if (button.disabled) return;
                
                let page = button.page;
                if (page != this.selectedValue) {
                    this.selectedValue = page;
                    this.$emit('update:modelValue', page);
                    this.$emit('input', page);
                    this.$emit('change', page);
                }
            }
        },
    }
</script>