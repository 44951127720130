export default [
    {
        path: '/property-analysis/:slug?',
        components: {
            default: () => import(/* webpackChunkName: "public.services.list-your-property" */ '@/views/Public/Services/ListYourProperty.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/services',
            label: 'Rent Your Home',
            floatingMenu: true,
            multiRoute: 'public.services.list-your-property',
            footerCtaType: 'rent',
            showAnalysisModal: true,
            metaData: {
                description: 'Earn easy income renting your vacation home, worry-free. List your property with our full-service, seamless platform.',
            },
        }
    },

    {
        path: '/hamptons-guide',
        components: {
            default: () => import(/* webpackChunkName: "public.leads.guides" */ '@/views/Public/Leads/Guides/Hamptons.vue'),
            mainMenu: () => import(/* webpackChunkName: "public.menu" */ '@/menus/Main/Public.vue'),
            footer: () => import(/* webpackChunkName: "public.footer" */ '@/components/Layout/FrontEndFooter.vue'),
        },
        meta: {
            authRequired: false,
            group: '/property-analysis',
            label: 'Hamptons Guide',
            multiRoute: 'public.leads.guides.hamptons',
        }
    },

    {
        path: '/quiz/town-vibe/:quizId?',
        alias: ['/quiz/town-vibe/share/:quizId?'],
        components: {
            default: () => import(/* webpackChunkName: "public.leads.quiz.town" */ '@/views/Public/Leads/Quizes/TownVibe.vue'),
        },
        meta: {
            authRequired: false,
            group: '/quiz',
            label: 'Which Hamptons town are you?',
            multiRoute: 'public.leads.quiz.town',
            isWidget: true,
        }
    },

    {
        path: '/quiz/town-vibe/:quizId(\\d+)/:hash',
        components: {
            default: () => import(/* webpackChunkName: "public.leads.quiz.town" */ '@/views/Public/Leads/Quizes/TownVibeResults.vue'),
        },
        meta: {
            authRequired: false,
            group: '/quiz',
            label: 'Which Hamptons town are you?',
            multiRoute: 'public.leads.quiz.town.results',
            isWidget: true,
        }
    },
    
    {
        path: '/subscribe-200',
        components: {
            default: () => import(/* webpackChunkName: "public.leads.subscribe.gift200" */ '@/views/Public/Leads/Subscribe/Gift200.vue'),
        },
        meta: {
            authRequired: false,
            group: '/subscribe',
            label: '$200 Off Your Next Stay',
            multiRoute: 'public.leads.subscribe.gift200',
            isWidget: true,
        }
    },

    {
        path: '/subscribe-ta',
        components: {
            default: () => import(/* webpackChunkName: "public.leads.subscribe.ta" */ '@/views/Public/Leads/Subscribe/TA.vue'),
        },
        meta: {
            authRequired: false,
            group: '/subscribe',
            label: 'Subscribe to our newsletter!',
            multiRoute: 'public.leads.subscribe.ta',
            isWidget: true,
        }
    },
];